import React, { FC } from 'react';
import { Button, ButtonProps } from '../Button';
import { Icon } from '../Icon';
import styles from './AutocompleteList.module.scss';

export const AutocompleteListItem: FC<React.PropsWithChildren<ButtonProps>> = ({ children, ...props }) => {
	return (
		<li className={styles.autocompleteListItem}>
			<Button {...props} variant="blank" className={styles.autocompleteListButton}>
				<span className={styles.autocompleteListButtonIcon}>
					<Icon.Location />
				</span>

				<span>{children}</span>
			</Button>
		</li>
	);
};

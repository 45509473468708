import * as _ from 'lodash';
import {Feature, Geometry} from 'geojson';
import {AutocompleteProperties} from './autocompleteHelper';
import {IconType} from '@components/Icon/Icon';
import {LocaleEnum, LocaleType} from '@localTypes/general';

type Input = {query?: string; rank?: number; limit: number};
type Extra = {
    addressdetails?: number;
    osm_ids?: string;
    lat?: number;
    lon?: number;
};

const placeRankToTypeMapping: Record<number, string> = {
    6: 'state',
    8: 'state',
    12: 'region',
    14: 'state_district',
    16: 'city',
    20: 'city_district',
};

const autocompleteTypeMapping: Record<LocaleType, Record<string, string>> = {
    [LocaleEnum.cs]: {
        address: 'adresní bod',
        highway: 'ulice',
        country: 'země',
        peak: 'vrchol',
        river: 'řeka',
        stream: 'potok',
        suburb: 'městská část',
        city: 'město',
        water: 'vodní plocha',
        hamlet: 'osada',
        tertiary: 'ulice',
        village: 'vesnice',
        secondary: 'ulice',
        station: 'stanice',
        primary: 'ulice',
        district: 'městská část / obvod',
        neighbourhood: 'čtvrť',
        state_district: 'okres',
        city_district: 'městská část',
        country_region: 'region',
        region: 'kraj',
        state: 'region soudržnosti',
        locality: 'lokalita',
        aerodrome: 'letiště',
        residential: 'ulice',
        mountain_range: 'pohoří',
        protected_area: 'chráněné území',
        subway: 'stanice metra',
        street: 'ulice',
        town: 'město',
        house: 'dům',
        isolated_dwelling: 'samota',
    },
    [LocaleEnum.sk]: {
        address: 'adresný bod',
        highway: 'ulica',
        country: 'krajina',
        peak: 'vrchol',
        river: 'rieka',
        stream: 'potok',
        suburb: 'mestská časť',
        city: 'mesto',
        water: 'vodná plocha',
        hamlet: 'dedinka',
        tertiary: 'ulica',
        village: 'dedina',
        secondary: 'ulica',
        station: 'stanice',
        primary: 'ulice',
        district: 'mestský obvod',
        neighbourhood: 'čtvrť',
        state_district: 'okres',
        city_district: 'mestská časť',
        country_region: 'region',
        region: 'kraj',
        state: 'kraj',
        locality: 'lokalita',
        aerodrome: 'letisko',
        residential: 'ulica',
        mountain_range: 'pohorie',
        protected_area: 'chránené územie',
        subway: 'stanice metra',
        street: 'ulice',
        town: 'dedina',
        house: 'dom',
        isolated_dwelling: 'samota',
    },
    [LocaleEnum.en]: {
        address: 'address',
        highway: 'street',
        country: 'country',
        peak: 'peak',
        river: 'river',
        stream: 'stream',
        suburb: 'neighbourhood',
        city: 'city',
        water: 'water area',
        hamlet: 'hamlet',
        tertiary: 'street',
        village: 'village',
        secondary: 'street',
        station: 'station',
        primary: 'street',
        district: 'city district',
        neighbourhood: 'neighbourhood',
        state_district: 'district',
        city_district: 'neighbourhood',
        country_region: 'macroregion',
        region: 'region',
        state: 'region',
        locality: 'place',
        aerodrome: 'aerodrome',
        residential: 'street',
        mountain_range: 'mountain range',
        protected_area: 'protected area',
        subway: 'subway station',
        street: 'street',
        town: 'town',
        house: 'house',
        isolated_dwelling: 'isolated dwelling',
    },
};

export const getAcceptLanguageValue = (locale: LocaleType) => {
    return `${locale},*;q=0.1`;
};

const getNominatimLabel = (feature: Feature<Geometry, AutocompleteProperties>) => {
    if (feature.properties.address && feature.properties.display_name && 'house_number' in feature.properties.address) {
        const parts = feature.properties.display_name.split(', ');
        const houseNumber = parts.shift();
        parts[0] += ' ' + houseNumber;
        return parts.join(', ');
    }

    return feature.properties.display_name ?? '';
};

const getIcon = (feature: Feature<Geometry, AutocompleteProperties>): IconType => {
    const cls = feature.properties.class;
    const type = feature.properties.type;
    const extratags = feature.properties.extratags;

    if (cls === 'natural') {
        return 'Mountain';
    } else if (cls === 'railway' && type === 'station' && extratags?.hasOwnProperty('station') && extratags.station === 'subway') {
        return 'Subway';
    }

    return 'Location';
};

export const deduplicate = (features: Feature<Geometry, AutocompleteProperties>[]) =>
    features.reduce((carry: Feature<Geometry, AutocompleteProperties>[], feature: Feature<Geometry, AutocompleteProperties>) => {
        const pos = carry.findIndex(
            (f) => f.properties.label === feature.properties.label && f.properties.class === feature.properties.class,
        );
        const orig = carry[pos];

        if (pos !== -1 && orig && (feature.properties.class === 'highway' || orig.properties.type === feature.properties.type)) {
            if (
                orig.properties.osm_type === 'R' ||
                (orig.properties.osm_type === 'W' && feature.properties.osm_type !== 'R') ||
                (orig.properties.osm_type === 'N' && feature.properties.osm_type === 'N')
            ) {
                return carry; // Do not include second output as it is not as usefull
            } else {
                carry[pos] = feature; // Change output
                return carry;
            }
        } else {
            carry.push(feature); // Add item as it is different
        }

        return carry;
    }, []);

const getNominatimPolygonThresholdByPlaceRank = (rank: number) => {
    if (rank < 8) {
        return 0.002;
    } else if (rank < 12) {
        return 0.001;
    } else if (rank <= 30) {
        return 0.001;
    }

    return 0;
};

export const nominatimQueryParameters = (
    {query, rank, limit}: Input,
    extra: Extra,
    withPolygon?: boolean,
    withExtraTags?: boolean,
    simplifyPolygon = true,
) => {
    const data: {
        limit: number;
        format: string;
        q?: string;
        polygon_geojson?: number;
        polygon_threshold?: 0 | 0.001 | 0.002;
        extratags?: number;
    } = {
        limit,
        format: 'geojson',
        q: query,
    };

    if (withPolygon) {
        data.polygon_geojson = 1;

        if (simplifyPolygon) {
            data.polygon_threshold = rank ? getNominatimPolygonThresholdByPlaceRank(rank) : 0;
        }
    }

    if (withExtraTags) {
        data.extratags = 1;
    }

    return _.assign({}, data, extra);
};

const getTypeKeyTranslation = (locale: LocaleType) => (type: string) => {
    if (!autocompleteTypeMapping[locale][type]) {
        return type;
    }

    return autocompleteTypeMapping[locale][type];
};

const getLocalizedType = (locale: LocaleType) => (feature: Feature<Geometry, AutocompleteProperties>) => {
    const cls = feature.properties.class;
    const type = feature.properties.type;
    const extratags = feature.properties.extratags;

    //console.log(feature.properties);

    if (['place', 'natural'].includes(cls)) {
        return getTypeKeyTranslation(locale)(type);
    } else if (cls === 'highway') {
        return getTypeKeyTranslation(locale)('street');
    } else if (cls === 'railway' && type === 'station' && extratags?.hasOwnProperty('station') && extratags.station === 'subway') {
        return getTypeKeyTranslation(locale)('subway');
    } else if (cls === 'boundary' && type !== 'administrative') {
        return getTypeKeyTranslation(locale)(type);
    } else if (cls === 'boundary' && type === 'administrative' && placeRankToTypeMapping[feature.properties.place_rank]) {
        return getTypeKeyTranslation(locale)(placeRankToTypeMapping[feature.properties.place_rank]);
    } else if (cls === 'building') {
        return getTypeKeyTranslation(locale)('house');
    }

    return '';
};

export const processFeature = (locale?: LocaleType) => (feature: Feature<Geometry, AutocompleteProperties>) => {
    const newFeature: Feature<Geometry, AutocompleteProperties> = JSON.parse(JSON.stringify(feature));

    newFeature.properties.label = getNominatimLabel(feature);
    newFeature.properties.icon = getIcon(feature);

    if (newFeature.properties.name?.startsWith('okres')) {
        // Trip 'okres' prefix
        newFeature.properties.name = newFeature.properties.name.substr(6);
        if (newFeature.properties.display_name) {
            newFeature.properties.display_name = newFeature.properties.display_name.substr(6);
        }
        if (newFeature.properties.label) {
            newFeature.properties.label = newFeature.properties.label.substr(6);
        }
    }

    if (newFeature.properties.type === 'address') {
        if (newFeature.properties.display_name) {
            const parts = newFeature.properties.display_name.split(', ');
            const number = parts.splice(0, 1)[0];
            parts[0] += ` ${number}`;
            newFeature.properties.display_name = parts.join(', ');
        }
        if (newFeature.properties.label) {
            const parts = newFeature.properties.label.split(', ');
            const number = parts.splice(0, 1)[0];
            parts[0] += ` ${number}`;
            newFeature.properties.label = parts.join(', ');
        }
    }

    if (locale) {
        newFeature.properties.local_type = getLocalizedType(locale)(feature);
    }

    return newFeature;
};

export const getCityLabel = (feature: Feature<Geometry, AutocompleteProperties>) => {
    const address = feature.properties.address ?? {};
    const parts = [
        address.hamlet,
        address.suburb,
        address.village,
        address.town,
        address.city,
        address.municipality,
        address.county,
        address.state,
        address.country,
    ];

    return parts
        .filter((part) => !!part)
        .filter((part, idx, arr) => arr.length === idx + 1 || arr[idx + 1] !== part) // eliminate duplicates
        .join(', ');
};

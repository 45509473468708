import {useCallback, useMemo} from 'react';
import Head from 'next/head';
import {useRouter} from 'next/router';
import {useLocale} from '@utils/locale';
import {getLocalizedRoute, Route} from '@utils/routes';
import {entries, LocaleType, nonNullArray} from '../../types/general';

type SeoHeaderProps = {
    title: string;
    description?: string;
    image?: string;
    keywords?: string;
    canonical?: {route: Route; params?: Record<string, any>};
    prev?: string;
    next?: string;
    robots?: string;
    hideDescription?: boolean;
} & (
    | {
          hrefLangs: Partial<Record<LocaleType, string>>;
          hrefLangRoute?: never;
          hrefLangRoutes?: never;
      }
    | {
          hrefLangs?: never;
          hrefLangRoute: {route: Route; params?: Record<string, any>};
          hrefLangRoutes?: Record<LocaleType, {route: Route; params?: Record<string, any>}>;
      }
);

export const SeoHeader = ({
    title,
    description,
    image,
    canonical,
    prev,
    next,
    robots,
    hideDescription,
    hrefLangs,
    hrefLangRoute,
    hrefLangRoutes,
}: SeoHeaderProps) => {
    const router = useRouter();
    const locale = useLocale();

    const domainLocale = router.domainLocales?.find((dl) => dl.defaultLocale === locale);
    const webUrl = (domainLocale?.http ? 'http' : 'https') + '://' + domainLocale?.domain + router.basePath + router.asPath;

    const getHrefLangUrl = useCallback(
        (locale: LocaleType, route: any, routeParams: any) => {
            const domainLocale = router.domainLocales?.find((dl) => dl.defaultLocale === locale);

            return (
                (domainLocale?.http ? 'http' : 'https') +
                '://' +
                domainLocale?.domain +
                router.basePath +
                getLocalizedRoute(route, locale, routeParams)
            );
        },
        [router.basePath, router.domainLocales],
    );
    const getCanonicalUrl = useCallback(
        (locale: LocaleType, route: any, routeParams: any) => {
            const domainLocale = router.domainLocales?.find((dl) => dl.defaultLocale === locale);

            return (
                (domainLocale?.http ? 'http' : 'https') +
                '://' +
                domainLocale?.domain +
                router.basePath +
                getLocalizedRoute(route, locale, routeParams)
            );
        },
        [router.basePath, router.domainLocales],
    );

    const getAbsoluteUrl = useCallback(
        (locale: LocaleType, url: any) => {
            const domainLocale = router.domainLocales?.find((dl) => dl.defaultLocale === locale);

            return (domainLocale?.http ? 'http' : 'https') + '://' + domainLocale?.domain + router.basePath + url;
        },
        [router.basePath, router.domainLocales],
    );

    const hrefLangsRoute = useMemo(
        () =>
            hrefLangRoute
                ? {
                      cs: getHrefLangUrl('cs', hrefLangRoute.route, hrefLangRoute.params),
                      en: getHrefLangUrl('en', hrefLangRoute.route, hrefLangRoute.params),
                      sk: getHrefLangUrl('sk', hrefLangRoute.route, hrefLangRoute.params),
                  }
                : hrefLangs,
        [getHrefLangUrl, hrefLangs, hrefLangRoute],
    );

    const hrefLangsOverride = useMemo(() => {
        if (hrefLangRoutes) {
            return {
                cs: getHrefLangUrl('cs', hrefLangRoutes.cs.route, hrefLangRoutes.cs.params),
                en: getHrefLangUrl('en', hrefLangRoutes.en.route, hrefLangRoutes.en.params),
                sk: getHrefLangUrl('sk', hrefLangRoutes.sk.route, hrefLangRoutes.sk.params),
            };
        }

        return null;
    }, [getHrefLangUrl, hrefLangRoutes]);

    const hrefLangsNew = hrefLangsOverride ?? hrefLangsRoute;
    const titleNew = title.replace(/\s+/g, ' ');
    const descriptionNew = description?.replace(/\s+/g, ' ');

    return (
        <Head>
            <title>{titleNew}</title>
            {!hideDescription && <meta name="description" key="description" content={descriptionNew} />}
            {robots && <meta name="robots" key="robots" content={robots} />}

            <meta name="twitter:title" content={titleNew} />
            <meta name="twitter:description" content={descriptionNew} />
            <meta name="twitter:url" content={webUrl} />
            {image && <meta name="twitter:image" content={image} />}

            <meta property="og:title" content={titleNew} />
            <meta property="og:description" content={descriptionNew} />
            <meta property="og:site_name" content={titleNew} />
            <meta property="og:url" content={webUrl} />
            {image && <meta property="og:image" content={image} />}

            {canonical && <link rel="canonical" href={getCanonicalUrl(locale, canonical.route, canonical.params)} />}
            {prev && <link rel="prev" href={getAbsoluteUrl(locale, prev)} />}
            {next && <link rel="next" href={getAbsoluteUrl(locale, next)} />}

            {nonNullArray(entries(hrefLangsNew)).map((item, idx) => (
                <link key={idx} rel="alternate" hrefLang={item[0]} href={item[1]} />
            ))}
        </Head>
    );
};

import {stringify} from 'qs';
import {LocaleEnum, LocaleType} from 'src/types/general';
import {Feature, FeatureCollection, GeoJsonProperties, Geometry} from 'geojson';
import {IconType} from '@components/Icon/Icon';
import {getAcceptLanguageValue, nominatimQueryParameters, processFeature} from './utils';

export type NominatimOsmId = `N${number}` | `R${number}` | `W${number}`;

export type AutocompleteProperties = {
    osm_type: 'R' | 'W' | 'N';
    osm_id: string;
    place_rank: number;
    local_type?: string;
    label: string;
    class: string;
    type: string;
    street: string;
    name?: string;
    city: string;
    house_number?: string;
    country_code: string;
    display_name?: string;
    icon?: IconType;
    extratags?: Record<string, any>;
    address?: Record<string, any>;
} & GeoJsonProperties;

// export class AutocompleteHelper {
//     constructor(
//         protected autocomplete_url: string,
//         protected nominatim_url: string,
//         protected street_url: string,
//         protected polygonOutput = false,
//         protected simplifyPolygon = true,
//         protected suggestionLimit = 20,
//         protected locale: LocaleType = LocaleEnum.cs,
//         protected address: boolean | null = null,
//         protected preferredCountry = '',
//         protected withExtraTags = false,
//     ) {}
// }

export const search =
    (nominatim_url: string, locale: LocaleType = LocaleEnum.cs, withPolygon = false, withExtraTags = false, suggestionLimit = 20) =>
    (text: string) => {
        const data = nominatimQueryParameters(
            {
                query: text,
                limit: suggestionLimit,
            },
            {},
            withPolygon,
            withExtraTags,
        );
        return fetch(`${nominatim_url}/search?` + stringify(data), {
            method: 'get',
            headers: {
                'Accept-Language': getAcceptLanguageValue(locale),
            },
        })
            .then((response) => response.json())
            .then((response: FeatureCollection<Geometry, AutocompleteProperties>) => {
                const features = response.features.map((feature) => processFeature()(feature));

                return {...response, features};
            });
    };

export const autocomplete =
    (autocomplete_url: string, locale: LocaleType = LocaleEnum.cs, preferredCountry = '', address = false, suggestionLimit = 20) =>
    (text: string, options?: Omit<RequestInit, 'method' | 'headers'>, from = 0) => {
        if (text === '') {
            return Promise.resolve({
                type: 'FeatureCollection',
                features: [],
            } as FeatureCollection<Geometry, AutocompleteProperties>);
        }

        const preferredCountryObj = preferredCountry ? {preferredCountry} : {};

        const data = {
            q: text,
            size: suggestionLimit,
            address: address ? 1 : 0,
            ...preferredCountryObj,
            from,
        };

        return fetch(`${autocomplete_url}/autocomplete?` + stringify(data), {
            method: 'get',
            headers: {
                'Accept-Language': getAcceptLanguageValue(locale),
            },
            ...options,
        })
            .then((response) => response.json())
            .then((response: FeatureCollection<Geometry, AutocompleteProperties>) => {
                const features = response.features.map((feature) => processFeature(locale)(feature));

                return {...response, features};
            });
    };

const getStreetPolygon = (street_url: string) => (street_id: string | number) =>
    fetch(`${street_url}?id=${street_id}`).then((response) => response.json());

export const lookup =
    (
        nominatim_url: string,
        street_url: string,
        locale: LocaleType = LocaleEnum.cs,
        withPolygon = false,
        withExtraTags = false,
        suggestionLimit = 20,
        simplifyPolygon = true,
    ) =>
    (osmId: NominatimOsmId | NominatimOsmId[], rank?: number) => {
        const data = nominatimQueryParameters(
            {
                rank,
                //query: suggestion.properties.display_name,
                limit: suggestionLimit,
            },
            {
                addressdetails: 1,
                osm_ids: Array.isArray(osmId) ? osmId.join(',') : osmId,
            },
            withPolygon,
            withExtraTags,
            simplifyPolygon,
        );

        return fetch(`${nominatim_url}/lookup?` + stringify(data), {
            method: 'get',
            headers: {
                'Accept-Language': getAcceptLanguageValue(locale),
            },
        })
            .then((response) => response.json())
            .then(async (response: FeatureCollection<Geometry, AutocompleteProperties>) => {
                const features = response.features.map((feature) => processFeature(locale)(feature));
                for (const feature of features) {
                    if (['LineString', 'MultiLineString'].includes(feature.geometry.type)) {
                        const polygon = await getStreetPolygon(street_url)(feature.properties.osm_id);
                        if (polygon) {
                            feature.geometry = polygon;
                        }
                    }
                }
                return {...response, features};
            });
    };

export const suggestionDetail =
    (nominatim_url: string, street_url: string, locale: LocaleType = LocaleEnum.cs, withPolygon = false, withExtraTags = false) =>
    (suggestion: Feature<Geometry, AutocompleteProperties>) => {
        const type = suggestion.properties.osm_type;

        return lookup(
            nominatim_url,
            street_url,
            locale,
            withPolygon,
            withExtraTags,
        )((type + suggestion.properties.osm_id) as NominatimOsmId, suggestion.properties.place_rank);
    };

export const reverse =
    (nominatim_url: string, locale: LocaleType = LocaleEnum.cs, withPolygon = false, withExtraTags = false, suggestionLimit = 20) =>
    (center: number[]) => {
        const data = nominatimQueryParameters(
            {
                limit: suggestionLimit,
            },
            {
                lat: center[1],
                lon: center[0],
            },
            withPolygon,
            withExtraTags,
        );
        return fetch(`${nominatim_url}/reverse?` + stringify(data), {
            method: 'get',
            headers: {
                'Accept-Language': getAcceptLanguageValue(locale),
            },
        })
            .then((response) => response.json())
            .then((response: FeatureCollection<Geometry, AutocompleteProperties>) => {
                const features = response.features.map((feature) => processFeature()(feature));

                return {...response, features};
            });
    };

import classNames from 'classnames';
import React, { FC } from 'react';
import styles from './AutocompleteList.module.scss';
import { AutocompleteListHighlighted } from './AutocompleteListHighlighted';
import { AutocompleteListItem } from './AutocompleteListItem';

export const AutocompleteList: FC<React.PropsWithChildren> = ({ children }) => {
	return <ul className={classNames(styles.autocompleteList, 'autocompleteList')}>{children}</ul>;
};

export default Object.assign(AutocompleteList, {
	Highlighted: AutocompleteListHighlighted,
	Item: AutocompleteListItem,
});
